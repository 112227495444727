<template>
  <modal ref="modal" class-name="w-full sm:max-w-500px">
    <h3 class="font-bold text-xl mb-5">Increase limits</h3>

    <p class="text-gray-600 mb-10">
      Update a user's credit and standard loan limits
    </p>

    <form @submit.prevent="submit">
      <form-group
        v-model="form.data.credit_limit.value"
        name="credit_limit"
        :form="form"
        type="money"
      >
        Credit Limit ₦
      </form-group>

      <form-group
        v-model="form.data.standard_loan_limit.value"
        name="standard_loan_limit"
        :form="form"
        type="money"
      >
        Standard Loan Limit ₦
      </form-group>

      <form-group
        v-model="form.data.standard_loan_interest_rate.value"
        name="standard_loan_interest_rate"
        :form="form"
        type="number"
      >
        Standard Loan Interest Rate %
      </form-group>

      <form-group
        v-model="form.data.comments.value"
        name="comments"
        :form="form"
        type="textarea"
      >
        Comments
      </form-group>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-5">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <button
        type="submit"
        class="btn btn-blue btn-md"
        :disabled="form.loading"
      >
        <sm-loader-white v-if="form.loading" />
        <span v-else>Submit</span>
      </button>
    </form>
  </modal>
</template>

<script>
export default {
  name: 'IncreaseLimit',
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: this.$options.basicForm([
        'credit_limit',
        'standard_loan_limit',
        'standard_loan_interest_rate',
        'comments',
      ]),
    };
  },
  mounted() {
    this.mapData();
  },
  computed: {},
  watch: {
    selectedUser() {
      this.mapData();
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    mapData() {
      const {
        credit_limit: creditLimit = '',
        standard_loan_limit: standardLoanLimit = '',
        standard_loan_interest_rate: standardLoanInterestRate,
      } = this.selectedUser?.profile || {};

      this.form.data.credit_limit.value = creditLimit;
      this.form.data.standard_loan_limit.value = standardLoanLimit;
      this.form.data.standard_loan_interest_rate.value =
        standardLoanInterestRate;
    },
    open() {
      this.$refs.modal.open();
    },
    async submit() {
      if (!this.validateForm(this.form)) {
        return;
      }

      this.form.error = false;
      this.form.loading = true;

      await this.sendRequest(
        'admin.users.increaseLimit',
        this.selectedUser?.id,
        {
          data: this.getFormData(),
          success: () => {
            this.$emit('success');
            this.$success({
              title: 'Operation Successful',
              body: 'The users limits have been increased',
              button: 'Okay',
            });
            this.close();
          },
          error: (error) => {
            this.form.error = error;
            this.mapFormErrors(this.form, error?.response?.data?.errors);
          },
        }
      );

      this.form.loading = false;
    },
  },
};
</script>
